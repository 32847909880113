import React, { useEffect, useState } from 'react';
import { Facebook } from 'lucide-react';

const FacebookLogin = () => {
  const [sessionInfo, setSessionInfo] = useState('');
  const [sdkResponse, setSdkResponse] = useState('');

  useEffect(() => {
    // Initialize Facebook SDK
    window.fbAsyncInit = function() {
      window.FB.init({
        appId: '493407539733380',
        autoLogAppEvents: true,
        xfbml: true,
        version: 'v21.0'
      });
    };

    // Load Facebook SDK
    const loadFacebookSDK = () => {
      const script = document.createElement('script');
      script.src = 'https://connect.facebook.net/en_US/sdk.js';
      script.async = true;
      script.defer = true;
      script.crossOrigin = 'anonymous';
      document.body.appendChild(script);
    };

    loadFacebookSDK();

    // Add message event listener
    const handleMessage = (event) => {
      if (event.origin !== "https://www.facebook.com" && event.origin !== "https://web.facebook.com") {
        return;
      }

      try {
        const data = JSON.parse(event.data);
        if (data.type === 'WA_EMBEDDED_SIGNUP') {
          if (data.event === 'FINISH') {
            const { phone_number_id, waba_id } = data.data;
            console.log("Phone number ID ", phone_number_id, " WhatsApp business account ID ", waba_id);
          } else if (data.event === 'CANCEL') {
            const { current_step } = data.data;
            console.warn("Cancel at ", current_step);
          } else if (data.event === 'ERROR') {
            const { error_message } = data.data;
            console.error("error ", error_message);
          }
        }
        setSessionInfo(JSON.stringify(data, null, 2));
      } catch {
        console.log('Non JSON Responses', event.data);
      }
    };

    window.addEventListener('message', handleMessage);

    // Cleanup
    return () => {
      window.removeEventListener('message', handleMessage);
    };
  }, []);

  const fbLoginCallback = (response) => {
    if (response.authResponse) {
      const code = response.authResponse.code;
      // The returned code must be transmitted to your backend first and then
      // perform a server-to-server call from there to our servers for an access token.
    }
    setSdkResponse(JSON.stringify(response, null, 2));
  };

  const launchWhatsAppSignup = () => {
    // Launch Facebook login
    window.FB.login(fbLoginCallback, {
      config_id: '1744455813003229', // configuration ID goes here
      response_type: 'code', // must be set to 'code' for System User access token
      override_default_response_type: true, // when true, any response types passed in the "response_type" will take precedence over the default types
      extras: {
        setup: {},
        featureType: '',
        sessionInfoVersion: '2',
      }
    });
  };

  return (
    <div className="container">
    <div className="login-card">
      <button onClick={launchWhatsAppSignup} className="facebook-button">
        <Facebook size={24} />
        <span>Continue with Facebook</span>
      </button>

      <div className="info-section">
        <h3 className="info-title">Why Login with Facebook?</h3>
        <div className="benefits-list">
          <p className="benefit-item">
            <span className="bullet">•</span>
            Connect Your WhatsApp Business Account with Kunk.ai
          </p>
        </div>

        <div className="privacy-notice">
          <p>
            By continuing, you agree to our Terms of Service and Privacy Policy.
          </p>
        </div>
      </div>
    </div>

    <style jsx>{`
      .container {
        display: flex;
        align-items: center;
        justify-content: center;
        min-height: 100vh;
        padding: 1rem;
      }

      .login-card {
        width: 100%;
        max-width: 440px;
        border-radius: 8px;
        border: 1px solid #e5e7eb;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
        padding: 32px 24px;
      }

      .facebook-button {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 12px;
        padding: 14px 24px;
        background-color: #1877f2;
        color: white;
        border: none;
        border-radius: 6px;
        font-size: 16px;
        font-weight: 600;
        cursor: pointer;
        transition: background-color 0.2s ease;
        margin-bottom: 32px;
      }

      .facebook-button:hover {
        background-color: #1664d9;
      }

      .info-section {
        color: #374151;
      }

      .info-title {
        font-size: 18px;
        font-weight: 600;
        margin: 0 0 16px 0;
        color: #111827;
      }

      .benefits-list {
        margin-bottom: 24px;
      }

      .benefit-item {
        display: flex;
        align-items: flex-start;
        margin: 0;
        line-height: 1.5;
        color: #4b5563;
      }

      .bullet {
        margin-right: 8px;
      }

      .privacy-notice {
        padding-top: 24px;
        border-top: 1px solid #f3f4f6;
        font-size: 14px;
        color: #6b7280;
      }

      .privacy-notice p {
        margin: 0;
        line-height: 1.5;
      }

      @media (max-width: 480px) {
        .login-card {
          padding: 24px 16px;
        }
        
        .facebook-button {
          padding: 12px 20px;
        }
      }
    `}</style>
  </div>
  );
};

export default FacebookLogin;